import { useMemo } from 'react';
import { sort } from 'ramda';

import { useAppointments, useBooking } from 'hooks';
import { AppointmentsByDepartmentProvider } from 'types';
import { sortAppointmentsByFirstAvailable } from 'utils/appointment';
import { getAllowedAppointmentTypeIds, getAllowedProviders } from 'utils/provider';

/**
 * This hook loads and groups the open appointments per Provider, Department and VisitType.
 */
const useAppointmentsGroups = (options?: { featuredProviderId?: number }) => {
    const { booking } = useBooking();
    const {
        data: appointments,
        isFetching,
        refetch: refetchAppointments,
        isFetchedAfterMount: isAppointmentsFetchedAfterMount,
    } = useAppointments(booking);

    const { department, providersAndCareTeams, departmentAndRelatedDepartments, reason, market } = booking;

    const appointmentsGroupings = useMemo(() => {
        if (!appointments || !reason || !market || !department) {
            return [];
        }

        const allowedAppointmentTypeIds = getAllowedAppointmentTypeIds(reason, market, department.departmentid);
        const filteredAppointments = appointments.filter(({ appointmenttypeid }) =>
            allowedAppointmentTypeIds.includes(appointmenttypeid)
        );

        const allowedProvidersByDepartment = getAllowedProviders(departmentAndRelatedDepartments, reason, providersAndCareTeams);

        const appointmentsGrouped = department
            ? allowedProvidersByDepartment.flatMap<AppointmentsByDepartmentProvider>(([providerDepartment, providers]) =>
                  providers.map((provider) => ({
                      department: providerDepartment,
                      provider,
                      showCareTeamHeader: false,
                      appointments: filteredAppointments.filter(
                          (appointment) =>
                              appointment.withinAllowedTimeRange &&
                              appointment.providerid === provider.providerid &&
                              providerDepartment.departmentid === appointment.departmentid
                      ),
                  }))
              )
            : [];

        const appointmentsSorted = sort(
            (a: AppointmentsByDepartmentProvider, b: AppointmentsByDepartmentProvider) =>
                sortAppointmentsByFirstAvailable(a.appointments[0], b.appointments[0]),
            sort((a, b) => a.provider.displayname.localeCompare(b.provider.displayname), appointmentsGrouped)
        );

        const sortedGroups: Record<string, AppointmentsByDepartmentProvider[]> = {
            primaryProviderGroups: [],
            disabledForSchedulingGroup: [],
            uncategorized: [],
        };

        appointmentsSorted.map((group) => {
            if (
                group.provider.providerid === booking.provider?.providerid ||
                group.provider.providerid === options?.featuredProviderId
            ) {
                sortedGroups['primaryProviderGroups'].push(group);
            } else if (group.provider.hubspot?.enabledForScheduling === false) {
                sortedGroups['disabledForSchedulingGroup'].push(group);
            } else {
                sortedGroups['uncategorized'].push(group);
            }
        });

        return [
            // primary provider should always be at the top
            // We only show the care team header for the first care team member
            // providers disabled for scheduling in hubspot should always show last
            ...sortedGroups['primaryProviderGroups'],
            ...sortedGroups['uncategorized'].map((group, groupIndex) =>
                groupIndex === 0 ? { ...group, showCareTeamHeader: !!sortedGroups['primaryProviderGroups'].length } : group
            ),
            ...sortedGroups['disabledForSchedulingGroup'],
        ];
    }, [
        appointments,
        reason,
        market,
        department,
        departmentAndRelatedDepartments,
        providersAndCareTeams,
        booking.provider?.providerid,
        options?.featuredProviderId,
    ]);

    return {
        refetchAppointments,
        appointmentsGroupings,
        isAppointmentsFetching: isFetching,
        isAppointmentsFetchedAfterMount,
    };
};

export { useAppointmentsGroups };
