import { BuildingIcon } from '@village/icons';
import { Text } from '@village/ui';
import { prop } from 'ramda';
import { FC, useEffect, useMemo, useState } from 'react';
import { differenceInCalendarDays } from 'date-fns';

import { DepartmentField, HubspotDepartmentField } from 'components/fields';
import {
    useAppointmentsGroups,
    useBooking,
    useCareTeam,
    useDepartments,
    useMarkets,
    useProviders,
    useReasons,
    useAppOptions,
    useCaptureCountlyEvent,
    useSchedulingNotAvailable,
} from 'hooks';
import { Appointment, Booking, Department, DepartmentHubspotInfo } from 'types';
import * as Styled from '../styles';
import { Banner } from 'components/banner';
import { DatesNavigation, AppointmentsCard } from 'widget/components';
import { SchedulingNotAvailable } from 'components/scheduling-not-available';
import { CenteredSpinner } from 'components/centered-spinner';
import { ReasonField } from 'widget/components/fields';
import { IsExistingPatientField } from 'components/fields/is-existing-patient';
import { getDefaultReason } from 'widget/utils/reason';
import { filterReasonsByProviders } from 'utils/reason';

interface Props {
    onSelectAppointment: (appointment: Appointment, booking: Booking) => void;
}

const ProviderWidget: FC<Props> = ({ onSelectAppointment }) => {
    const { setBookingField, booking } = useBooking();
    const widgetOptions = useAppOptions('providerWidget');
    const { data: markets, isFetching: isMarketsFetching } = useMarkets();
    const { data: providers, isFetching: isProvidersFetching, isFetched: isProvidersFetched } = useProviders(booking);
    const { data: allReasons, isFetching: isReasonsFetching } = useReasons(booking);
    const { data: careTeam, isFetching: isCareTeamsFetching } = useCareTeam(booking);
    const [providerDepartments, setProviderDepartments] = useState<Department[] | DepartmentHubspotInfo[]>([]);
    const { data: departments, isFetching: isDepartmentsFetching } = useDepartments(booking);
    const { isAppointmentsFetching, appointmentsGroupings } = useAppointmentsGroups();
    const { schedulingNotAvailableReason, setSchedulingUnavailableReason } = useSchedulingNotAvailable();
    const { addCountlyEvent } = useCaptureCountlyEvent();

    /**
     * Only used for providers that are disabled for scheduling as they will not go through a booking flow
     */
    const [disabledForSchedulingLocation, setDisabledForSchedulingLocation] = useState<DepartmentHubspotInfo | null>(null);
    const handleDisabledLocationChange = (value: DepartmentHubspotInfo) => {
        setDisabledForSchedulingLocation(value);
    };

    /**
     * Allow only reasons that map to the primary provider
     */
    const reasons = useMemo(() => {
        if (!booking.department || !booking.provider || !allReasons) {
            return [];
        }

        const filteredReasons = filterReasonsByProviders(allReasons, [booking.provider], booking.departmentAndRelatedDepartments);

        setSchedulingUnavailableReason(
            filteredReasons.length === 0 && booking.isExistingPatient === false ? 'NO_REASONS_NEW_PATIENTS' : null
        );

        return filteredReasons;
    }, [
        booking.department,
        booking.provider,
        booking.departmentAndRelatedDepartments,
        booking.isExistingPatient,
        allReasons,
        setSchedulingUnavailableReason,
    ]);

    const location = useMemo(() => {
        if (widgetOptions.enabledForScheduling === true) {
            return widgetOptions.departmentsList.find(({ id }) => Number(id) === booking.department?.departmentid);
        } else {
            if (!disabledForSchedulingLocation) {
                setDisabledForSchedulingLocation(widgetOptions.departmentsList[0]);
            }
            return disabledForSchedulingLocation;
        }
    }, [
        booking.department?.departmentid,
        disabledForSchedulingLocation,
        widgetOptions.departmentsList,
        widgetOptions.enabledForScheduling,
    ]);

    useEffect(() => {
        if (markets && !booking.market) {
            const market = markets.find(({ market_key }) => market_key === widgetOptions.marketKey);
            setBookingField({ market });
            setSchedulingUnavailableReason(!market ? 'NO_MARKET' : null);
        }
    }, [setBookingField, markets, widgetOptions.marketKey, booking.market, setSchedulingUnavailableReason]);

    useEffect(() => {
        if (widgetOptions.enabledForScheduling === false) {
            setProviderDepartments(widgetOptions.departmentsList.filter((department) => department.id !== null));
        } else {
            if (departments && !booking.department) {
                const departmentsListIds = widgetOptions.departmentsList.map(prop('id')).map(Number);
                const schedulableDepartments = booking.market?.schedulable_department_ids ?? [];
                const departmentsLookup = departments.filter(
                    ({ departmentid }) =>
                        departmentsListIds.includes(departmentid) && schedulableDepartments.includes(departmentid)
                );

                const defaultDepartment = departmentsLookup.find(
                    ({ departmentid }) => departmentid === widgetOptions.defaultDepartmentId
                );

                const department = defaultDepartment ?? departmentsLookup[0];

                setProviderDepartments(departmentsLookup);
                setBookingField({ department });
                setSchedulingUnavailableReason(!department ? 'NO_DEPARTMENT' : null);
            }
        }
    }, [setBookingField, departments, widgetOptions, booking.department, booking.market, setSchedulingUnavailableReason]);

    useEffect(() => {
        if (reasons.length > 0 && !booking.reason) {
            const defaultReason = getDefaultReason(reasons, booking.isExistingPatient);
            const initialReason = reasons.find(({ id }) => id === widgetOptions.reasonId);
            setBookingField({ reason: initialReason ?? defaultReason });
        }
    }, [setBookingField, reasons, booking.isExistingPatient, booking.reason, widgetOptions.reasonId]);

    useEffect(() => {
        if (isProvidersFetched && !booking.provider) {
            const provider = providers?.find(({ npi }) => npi === widgetOptions.providerNpi);
            setBookingField({ provider });
            setSchedulingUnavailableReason(!provider ? 'NO_PROVIDER' : null);
        }
    }, [
        setBookingField,
        providers,
        widgetOptions.providerNpi,
        booking.provider,
        isProvidersFetched,
        setSchedulingUnavailableReason,
    ]);

    const handleSelectAppointment = (appointment: Appointment) => {
        const provider = booking.providersAndCareTeams.find(({ providerid }) => providerid === appointment.providerid);
        const isCareTeam = provider && careTeam?.find(({ npi }) => npi === provider.npi);

        // This property will be used to help identify the difference between the first day in
        // the widget scheduler and the day of the appointment that was selected.
        const scheduledDaysOut = differenceInCalendarDays(new Date(appointment.date), booking.date);

        addCountlyEvent({
            key: 'selectSlot',
            segmentation: {
                appointmentId: appointment.appointmentid,
                appointmentTypeId: appointment.appointmenttypeid,
                appointmentSlotOrigin: 'widget-provider',
                providerId: appointment.providerid,
                providerName: provider?.displayname,
                scheduledDaysOut,
                time: appointment.starttime,
                isCareTeam: isCareTeam ? 'Yes' : 'No',
            },
        });
        onSelectAppointment(appointment, booking);
    };

    const isFetching =
        isCareTeamsFetching || isAppointmentsFetching || isReasonsFetching || isProvidersFetching || isMarketsFetching;

    return (
        <Styled.Container>
            <Styled.Title>Book Appointment</Styled.Title>
            <Styled.EmergencyNotice>
                In case of emergency or life threatening illness, call 911 or go to your local ER
            </Styled.EmergencyNotice>

            <Styled.FieldsWrapper>
                {providerDepartments.length > 1 ? (
                    <Styled.DepartmentFieldWrapper>
                        <Banner status="warning" icon={BuildingIcon}>
                            <Text type="caption1">This provider sees patients at multiple locations.</Text>
                        </Banner>
                        {widgetOptions.enabledForScheduling === false && widgetOptions.departmentsList ? (
                            <HubspotDepartmentField
                                label="Location:"
                                departments={widgetOptions.departmentsList}
                                isSearchable={false}
                                location={location}
                                handleDisabledLocationChange={handleDisabledLocationChange}
                            />
                        ) : (
                            <DepartmentField
                                label="Location:"
                                departments={providerDepartments as Department[]}
                                isLoading={isDepartmentsFetching}
                                isSearchable={false}
                            />
                        )}
                    </Styled.DepartmentFieldWrapper>
                ) : null}
                <IsExistingPatientField />
                <ReasonField reasons={reasons} isReasonsFetching={isReasonsFetching} />
            </Styled.FieldsWrapper>

            <DatesNavigation isAppointmentsFetching={isAppointmentsFetching} />

            {(widgetOptions.enabledForScheduling === false && schedulingNotAvailableReason) ||
            schedulingNotAvailableReason === 'NO_REASONS_NEW_PATIENTS' ? (
                <SchedulingNotAvailable
                    reason={schedulingNotAvailableReason}
                    location={location}
                    disabledFor="provider"
                    displayIcon
                />
            ) : (
                <Styled.RelativeContainer>
                    {isFetching ? (
                        <Styled.SpinnerContainer>
                            <Styled.SpinnerWrapper>
                                <CenteredSpinner />
                            </Styled.SpinnerWrapper>
                        </Styled.SpinnerContainer>
                    ) : null}
                    <Styled.AppointmentsContainer $loading={isFetching}>
                        {appointmentsGroupings.map((group) => (
                            <AppointmentsCard
                                key={`${group.provider.providerid}-${group.department.departmentid}`}
                                appointmentsGroup={group}
                                isCareTeamMember={careTeam?.includes(group.provider) ?? false}
                                isAppointmentsFetching={isAppointmentsFetching}
                                onSelectAppointment={handleSelectAppointment}
                            />
                        ))}
                    </Styled.AppointmentsContainer>
                </Styled.RelativeContainer>
            )}
        </Styled.Container>
    );
};

export { ProviderWidget };
