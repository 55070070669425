import { Autocomplete, AutocompleteOption, AutocompleteProps } from '@village/ui';
import { sortBy, prop, values } from 'ramda';
import { useId, useMemo, useCallback, forwardRef, ReactElement } from 'react';

import { useBooking, useCaptureCountlyEvent } from 'hooks';
import { getHubspotHyphenatedDepartmentAddress, getHyphenatedDepartmentAddress } from 'utils/department';
import { Department, DepartmentHubspotInfo } from 'types';

interface DepartmentOption extends AutocompleteOption {
    departmentId: number | null;
}

interface DepartmentFieldProps extends Omit<AutocompleteProps<DepartmentOption, false>, 'onChange' | 'options' | 'value'> {
    label?: string;
    departments: Department[] | undefined;
}

interface HubspotDepartmentFieldProps extends Omit<AutocompleteProps<DepartmentOption, false>, 'onChange' | 'options' | 'value'> {
    label?: string;
    departments: DepartmentHubspotInfo[];
    location?: DepartmentHubspotInfo | null;
    handleDisabledLocationChange: (value: DepartmentHubspotInfo) => void;
}

/* Use Department Field component when options/handlers rely on booking details */

const DepartmentField = forwardRef<HTMLDivElement, DepartmentFieldProps>(
    ({ label, id, departments, ...props }, ref): ReactElement => {
        const localId = useId();
        const { booking, setBookingField } = useBooking();
        const { addCountlyEvent } = useCaptureCountlyEvent();
        const { isExistingPatient, market } = booking;

        const options = useMemo(() => {
            const allRelatedDepartments = values(market?.related_departments ?? {}).flatMap(
                (relatedDepartments) => relatedDepartments
            );

            const schedulableDepartments = market?.schedulable_department_ids ?? [];

            const unsortedOptions =
                departments
                    // 1. Filter out departments that are not schedulable for the market.
                    // 2. Filter out departments that are configured as related departments.
                    //    So that they are not shown in the dropdown.
                    ?.filter(
                        ({ departmentid }) =>
                            schedulableDepartments.includes(departmentid) && !allRelatedDepartments.includes(departmentid)
                    )
                    .map<DepartmentOption>((department) => ({
                        departmentId: department.departmentid,
                        value: department.departmentid.toString(),
                        label: getHyphenatedDepartmentAddress(department),
                    })) ?? [];

            return sortBy(prop('label'), unsortedOptions);
        }, [departments, market]);

        const handleOnChange = useCallback(
            (option: DepartmentOption | null) => {
                if (!booking.department) {
                    addCountlyEvent({ key: 'selectLocation' });
                }

                const department = departments?.find(({ departmentid }) => option?.departmentId === departmentid);
                setBookingField({ department });
            },
            [departments, booking.department, setBookingField, addCountlyEvent]
        );

        const selectedOption = options.find((option) => option.departmentId === booking.department?.departmentid);

        return (
            <div ref={ref}>
                <Autocomplete
                    id={id ?? localId}
                    aria-label={label}
                    blurInputOnSelect={true}
                    label={label}
                    isDisabled={isExistingPatient === undefined}
                    options={options}
                    onChange={handleOnChange}
                    value={selectedOption ?? null}
                    noOptionsMessage={() => 'No locations available'}
                    placeholder="Select your preferred location"
                    {...props}
                />
            </div>
        );
    }
);

/* Use HubspotDepartmentField component when options/handlers rely on Hubspot data */
/* i.e. when online scheduling is unavailable for a provider and unable to make a booking */

const HubspotDepartmentField = forwardRef<HTMLDivElement, HubspotDepartmentFieldProps>(
    ({ label, id, departments, location, handleDisabledLocationChange, ...props }, ref): ReactElement => {
        const options = useMemo(() => {
            const unsortedOptions =
                departments?.map((department) => ({
                    departmentId: department.id ? parseInt(department.id) : null,
                    value: department.id ? department.id.toString() : '',
                    label: getHubspotHyphenatedDepartmentAddress(department),
                })) ?? [];

            return sortBy(prop('label'), unsortedOptions);
        }, [departments]);

        const handleOnChange = useCallback(
            (option: DepartmentOption | null) => {
                const department = departments.find(({ id: hubspotId }) => option?.departmentId?.toString() === hubspotId);
                if (department) {
                    handleDisabledLocationChange(department);
                }
            },
            [departments, handleDisabledLocationChange]
        );

        const selectedOption = options?.find((option) => option.departmentId?.toString() === location?.id);

        return (
            <div ref={ref}>
                <Autocomplete
                    id={id}
                    aria-label={label}
                    blurInputOnSelect={true}
                    label={label}
                    isDisabled={false}
                    options={options}
                    onChange={handleOnChange}
                    value={selectedOption ?? null}
                    noOptionsMessage={() => 'No locations available'}
                    placeholder="Select your preferred location"
                    {...props}
                />
            </div>
        );
    }
);

export { DepartmentField, HubspotDepartmentField };
