import { CloudQuestionIcon, SvgIconProps, TriangleExclamationIcon } from '@village/icons';
import { forwardRef, PropsWithChildren, ReactNode, HTMLAttributes, FC } from 'react';

import * as Styled from './styles';

export interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
    title?: ReactNode;
    icon?: FC<SvgIconProps> | null;
    iconSize?: SvgIconProps['customSize'];
    status?: 'warning' | 'info' | 'critical';
    variant?: 'small' | 'default';
}

interface BannerAttributes {
    DefaultIcon: FC<SvgIconProps>;
    ariaRoleType: 'status' | 'alert' | 'critical';
}

const getBannerAttributes = (status: Props['status']): BannerAttributes => {
    switch (status) {
        case 'warning':
        case 'critical':
            return {
                DefaultIcon: TriangleExclamationIcon,
                ariaRoleType: 'alert',
            };
        default:
            return {
                DefaultIcon: CloudQuestionIcon,
                ariaRoleType: 'status',
            };
    }
};

const Banner = forwardRef<PropsWithChildren<HTMLDivElement>, Props>(
    ({ icon: Icon, title, children, status = 'info', variant = 'default', iconSize = 2, ...divProps }: Props, ref) => {
        const { DefaultIcon, ariaRoleType } = getBannerAttributes(status);

        return (
            <Styled.Container
                ref={ref}
                {...divProps}
                $hasTitleAndContent={title != null && children != null}
                $status={status}
                tabIndex={0}
                role={ariaRoleType}
            >
                {Icon ? <Icon customSize={iconSize} /> : Icon === null ? null : <DefaultIcon customSize={iconSize} />}
                <Styled.ContentWrapper>
                    {title != null ? <div>{title}</div> : null}
                    {children != null ? (
                        <Styled.Content $hasTitle={title != null} $variant={variant}>
                            {children}
                        </Styled.Content>
                    ) : null}
                </Styled.ContentWrapper>
            </Styled.Container>
        );
    }
);

export { Banner };
