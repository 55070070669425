import { CountlyEvent, Countly as CountlyTools, isStorageAvailable } from '@village/tools';

import { UTM_STORAGE_KEY__CMD, UTM_STORAGE_KEY__VMD } from 'constants/storage';
import { appConfig } from 'modules/app-config';
import type { CountlyKey, CountlyRemoteConfigPayload, CountlySegmentationParams, CountlyUserType } from 'types';
import { storage } from 'utils/storage';
import { getUtmParams } from 'utils/url';

const baseKeyMap: Record<CountlySegmentationParams['schedulerType'], string> = {
    citymd: 'citymd',
    locationWidget: 'widget_scheduler',
    providerWidget: 'widget_scheduler',
    main: 'new_scheduler',
    checkin: 'checkin',
    'authenticated-citymd': 'authenticated_citymd',
};

const userType = (storage.getItem('USER_TYPE') || 'STANDARD') as CountlyUserType;

const countlyOnBeforeSend = (
    event: CountlyEvent<CountlyKey, CountlySegmentationParams>
): CountlyEvent<string, CountlySegmentationParams> => {
    const schedulerType = event.segmentation?.schedulerType;
    const baseKey = baseKeyMap[schedulerType ?? 'main'];
    const utmParams = getUtmParams(schedulerType === 'citymd' ? UTM_STORAGE_KEY__CMD : UTM_STORAGE_KEY__VMD);

    return {
        ...event,
        key: `${baseKey}.${event.key}`,
        segmentation: {
            ...event.segmentation,
            environment: appConfig.environment,
            pathname: window.location.pathname,
            origin: window.location.origin,
            source: 'user',
            userType,
            ...utmParams,
        },
    };
};

const Countly = CountlyTools.setup<CountlyKey, CountlySegmentationParams, CountlyRemoteConfigPayload & Record<string, unknown>>({
    beforeSend: countlyOnBeforeSend,
});

const initCountly = () => {
    Countly.init({
        app_key: appConfig.countlyAppKey,
        namespace: 'public_scheduler',
        url: appConfig.countlyUrl,
        app_version: appConfig.version,
        storage: isStorageAvailable(window.localStorage) ? 'localstorage' : 'none',
    });
};

export { Countly, countlyOnBeforeSend, initCountly };
