import { useContext } from 'react';

import { AppOptionsContext } from 'contexts/app-options';
import {
    LocationWidgetAppOptions,
    ProviderWidgetAppOptions,
    MainAppOptions,
    AppOptions,
    CitymdAppOptions,
    AuthenticatedCitymdAppOptions,
} from 'types';

type GenericAppOptions<T> = T extends 'providerWidget'
    ? ProviderWidgetAppOptions
    : T extends 'locationWidget'
    ? LocationWidgetAppOptions
    : T extends 'citymd'
    ? CitymdAppOptions
    : T extends 'authenticated-citymd'
    ? AuthenticatedCitymdAppOptions
    : T extends 'allCitymdSchedulers'
    ? CitymdAppOptions | AuthenticatedCitymdAppOptions
    : MainAppOptions;

const useAppOptions = <T extends AppOptions['type'] | 'allCitymdSchedulers'>(type?: T): GenericAppOptions<T> => {
    const context = useContext(AppOptionsContext);

    if (context === undefined) {
        throw new Error('useAppOptions must be used within a AppOptionsContext');
    }

    if (type !== undefined && context.type !== type) {
        throw new Error(`Scheduler type mismatch, expecting ${type}, received ${context.type}`);
    }

    return context as GenericAppOptions<T>;
};

export { useAppOptions };
